import React from "react";
import ReactDOM from "react-dom/client";

import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";

import Home from "./pages/home";
import Menu from "./pages/menu";

import "./styles/global.css";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <BrowserRouter>
            <ScrollToTop/>
            <Routes>
                <Route path="/" element={ <Home/> }/>
                <Route path="/menu" element={ <Menu/> }/>
                <Route path="/menu.html" element={ <Menu/> }/>
                {/* Error 404 */}
                <Route path="*" element={ <div>Página no encontrada</div> }/>
            </Routes>
        </BrowserRouter>
    </>
);