import { Link } from "react-router-dom"
import { HashLink } from "react-router-hash-link"

export function Navbar({ active="", color}) {
    return(
        <div className="navbar section">
            <div className="content">
                <div className="navbar_ops left">
                    <Link to="/" className={ active == "home" ? color + " navbar_ops__el title principal" : color + " navbar_ops__el title" }>BENVENUTO</Link>
                    <HashLink to="/#historia" className={ color + " navbar_ops__el title" }>HISTORIA</HashLink>
                </div>
                <Link to="/" className="navbar_logo"><img src={"/images/logo-" + color + ".svg"} alt="Logo Vittore"/></Link>
                <div className="navbar_ops right">
                    <Link to="/menu" className={ active == "menu" ? color + " navbar_ops__el title principal" : color + " navbar_ops__el title" }>MENÚ</Link>
                    <HashLink to="/#contacto" className={ color + " navbar_ops__el title" }>CONTACTO</HashLink>
                    <a href="tel:6699862424" className={ color + " navbar_ops__el title" }>RESERVAR</a>
                </div>
            </div>
        </div>
    )
}