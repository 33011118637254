import menu from "./../styles/menu.module.css"
import cx from "classnames"

import { ensaladas, ensaladasExtras, antipasti, carne, pesce, paste, pizza, pizzasExtras, postres, vinos, vinos_espumosos, vinos_blancos, vinos_rosados, vinos_tintos, ron, tequila, vodka, bourbon, irish_whiskey, canadian_whiskey, scotch, single_malt, brandy, cognac, ginebra, mezcal, licores, italian, autore, mixologia, virgenes, digestivos, soft, cervezas, artesanales, cuau } from "../array/menu"
import { MenuElement, BebidaElement, BebidaTitle, BebitaTitleDef, PlatilloTitleDef } from "../components/Menu"
import { MenuFloat } from "../components/MenuFloat"

import { ResponsiveImage, Image } from "../components/Images"
import { Navbar } from "../components/Navigation"
import { Copyright, Footer } from "../components/Footer"

import { useEffect } from "react"

export default function Menu() {
    useEffect(() => {
        const handleScroll = () => {
            window.scrollY > 100 ? document.querySelector('.floating_menu_flag').classList.add('show') : document.querySelector('.floating_menu_flag').classList.remove('show');
        };
        window.addEventListener('scroll', handleScroll);
        return () => { window.removeEventListener('scroll', handleScroll); };
    }, []);

    return(
        <>
            <Navbar color="white" active="menu"/>
            <div className={ cx(menu.header, 'section') }>
                <ResponsiveImage route="menu/hero" alt="Platillo" classes={ menu.header_bg }/>
                <div className={ cx(menu.header_title, 'title') }>MENÚ</div>
            </div>

            <div className={ menu.menu_con }>
                { /* Antipasti */}
                <div id="antipasti" className={ menu.menu_el }>
                    <Image classes={ menu.menu_el_poster } route="menu/antipasti" alt="Antipasti poster"/>
                    <div className={ menu.menu_el_platillos }>
                        <img className={ menu.menu_el_platillos__img } src="/images/menu/antipasti.svg" alt="antipasti icon"/>
                        <div className={ cx(menu.menu_el_platillos__t1, 'title') }>ANTIPASTI</div>
                        <div className={ menu.menu_el_platillos__sep }></div>
                        { ensaladas.map((el, index) => <MenuElement key={ index } platillo={ el }/>) }
                        <PlatilloTitleDef nombre="PUEDES AGREGAR A TU ENSALADA" />
                        { ensaladasExtras.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <div className={ menu.menu_el_platillos__sep }></div>
                        { antipasti.map((el, index) => <MenuElement key={ index } platillo={ el }/>) }
                    </div>

                    <div className={ menu.menu_el__disclaimer }>“AÚN Y CUANDO MANEJAMOS ALTAS NORMAS DE CALIDAD E HIGIENE, EL CONSUMO DE ALIMENTOS POCO COCINADOS O CRUDOS ES BAJO RESPONSABILIDAD DEL CONSUMIDOR POR EL RIESGO QUE ELLO IMPLICA”.</div>
                </div>

                { /* Carne e pollame */}
                <div id="carne" className={ menu.menu_el }>
                    <Image classes={ menu.menu_el_poster } route="menu/carne" alt="Carne e pollame poster"/>
                    <div className={ menu.menu_el_platillos }>
                        <img className={ menu.menu_el_platillos__img } src="/images/menu/carne.svg" alt="Carne e pollame icon"/>
                        <div className={ cx(menu.menu_el_platillos__t1, 'title') }>CARNE E POLLAME</div>
                        <div className={ menu.menu_el_platillos__sep }></div>
                        { carne.map((el, index) => <MenuElement key={ index } platillo={ el }/>) }
                    </div>
                </div>

                { /* Pesce */}
                <div id="pesce" className={ menu.menu_el }>
                    <Image classes={ menu.menu_el_poster } route="menu/pescado" alt="Pesce poster"/>
                    <div className={ menu.menu_el_platillos }>
                        <img className={ menu.menu_el_platillos__img } src="/images/menu/pesce.svg" alt="Pesce icon"/>
                        <div className={ cx(menu.menu_el_platillos__t1, 'title') }>PESCE</div>
                        <div className={ menu.menu_el_platillos__sep }></div>
                        { pesce.map((el, index) => <MenuElement key={ index } platillo={ el }/>) }
                    </div>
                </div>

                { /* Paste */}
                <div id="paste" className={ menu.menu_el }>
                    <Image classes={ menu.menu_el_poster } route="menu/pasta" alt="Paste poster"/>
                    <div className={ menu.menu_el_platillos }>
                        <img className={ menu.menu_el_platillos__img } src="/images/menu/paste.svg" alt="Paste icon"/>
                        <div className={ cx(menu.menu_el_platillos__t1, 'title') }>PASTE</div>
                        <div className={ menu.menu_el_platillos__sep }></div>
                        { paste.map((el, index) => <MenuElement key={ index } platillo={ el }/>) }
                    </div>
                </div>

                { /* Pizza al forno */}
                <div id="pizza" className={ menu.menu_el }>
                    <Image classes={ menu.menu_el_poster } route="menu/pizza" alt="Pizza poster"/>
                    <div className={ menu.menu_el_platillos }>
                        <img className={ menu.menu_el_platillos__img } src="/images/menu/pizza.svg" alt="Pizza icon"/>
                        <div className={ cx(menu.menu_el_platillos__t1, 'title') }>PIZZA AL FORNO</div>
                        <div className={ menu.menu_el_platillos__sep }></div>
                        { pizza.map((el, index) => <MenuElement key={ index } platillo={ el }/>) }
                        <PlatilloTitleDef nombre="EXTRAS"/>
                        { pizzasExtras.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                    </div>
                    <div className={ menu.menu_el__disclaimer }> De tener alguna alergia o restricción alimenticia, menciónelo a su mesero antes de ordenar. El consumo de pescados y mariscos crudos representan un riesgo para la salud y es responsabilidad de quien los consume.<br/>Todos nuestros precios incluyen I.V.A. La propina es sugerida más no obligatoria.</div>
                </div>

                { /* Postres */}
                <div id="postres" className={ menu.menu_el }>
                    <Image classes={ menu.menu_el_poster } route="menu/postre" alt="Postres poster"/>
                    <div className={ menu.menu_el_platillos }>
                        <img className={ menu.menu_el_platillos__img } src="/images/menu/postres.svg" alt="Postres icon"/>
                        <div className={ cx(menu.menu_el_platillos__t1, 'title') }>POSTRES</div>
                        <div className={ menu.menu_el_platillos__sep }></div>
                        { postres.map((el, index) => <MenuElement key={ index } platillo={ el }/>) }
                    </div>
                </div>

                { /* Vinos de mesa
                <div id="vinos" className={ menu.menu_el }>
                    <Image classes={ menu.menu_el_poster } route="menu/vino" alt="vinos poster"/>
                    <div className={ menu.menu_el_platillos }>
                        <img className={ menu.menu_el_platillos__img } src="/images/menu/vinos.svg" alt="Vinos de mesa icon"/>
                        <div className={ cx(menu.menu_el_platillos__t1, 'title') }>VINOS DE MESA</div>
                        <BebidaTitle titulo="CHAMPAGNE"/>
                        { vinos.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <BebidaTitle titulo="VINOS ESPUMOSOS"/>
                        { vinos_espumosos.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <BebidaTitle titulo="VINOS BLANCOS"/>
                        { vinos_blancos.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <BebidaTitle titulo="VINOS ROSADOS"/>
                        { vinos_rosados.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <BebidaTitle titulo="VINOS TINTOS"/>
                        { vinos_tintos.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                    </div>
                </div>
                 */}

                { /* Destilados */}
                <div id="destilados" className={ menu.menu_el }>
                    <Image classes={ menu.menu_el_poster } route="menu/destilados" alt="Destilados poster"/>
                    <div className={ menu.menu_el_platillos }>
                        <img className={ menu.menu_el_platillos__img } src="/images/menu/destilados.svg" alt="Destilados icon"/>
                        <div className={ cx(menu.menu_el_platillos__t1, 'title') }>DESTILADOS</div>
                        <BebitaTitleDef nombre="RON" botella="Botella" botellaMl="completa" copa="Trago" copaMl="160ml / 5.4 oz"/>
                        { ron.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <BebitaTitleDef nombre="TEQUILA" botella="Botella" botellaMl="completa" copa="Trago" copaMl="160ml / 5.4 oz"/>
                        { tequila.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <BebitaTitleDef nombre="VODKA" botella="Botella" botellaMl="completa" copa="Trago" copaMl="160ml / 5.4 oz"/>
                        { vodka.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <BebitaTitleDef nombre="BOURBON" botella="Botella" botellaMl="completa" copa="Trago" copaMl="160ml / 5.4 oz"/>
                        { bourbon.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <BebitaTitleDef nombre="IRISH WHISKEY" botella="Botella" botellaMl="completa" copa="Trago" copaMl="160ml / 5.4 oz"/>
                        { irish_whiskey.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <BebitaTitleDef nombre="CANADIAN WHISKEY" botella="Botella" botellaMl="completa" copa="Trago" copaMl="160ml / 5.4 oz"/>
                        { canadian_whiskey.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <BebitaTitleDef nombre="SCOTCH WHISKY" botella="Botella" botellaMl="completa" copa="Trago" copaMl="160ml / 5.4 oz"/>
                        { scotch.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <BebitaTitleDef nombre="SINGLE MALT" botella="Botella" botellaMl="completa" copa="Trago" copaMl="160ml / 5.4 oz"/>
                        { single_malt.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <BebitaTitleDef nombre="BRANDY" botella="Botella" botellaMl="completa" copa="Trago" copaMl="160ml / 5.4 oz"/>
                        { brandy.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <BebitaTitleDef nombre="COGNAC" botella="Botella" botellaMl="completa" copa="Trago" copaMl="160ml / 5.4 oz"/>
                        { cognac.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <BebitaTitleDef nombre="GINEBRA" botella="Botella" botellaMl="completa" copa="Trago" copaMl="160ml / 5.4 oz"/>
                        { ginebra.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <BebitaTitleDef nombre="MEZCAL" botella="Botella" botellaMl="completa" copa="Trago" copaMl="160ml / 5.4 oz"/>
                        { mezcal.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <BebitaTitleDef nombre="LICORES" copa="Trago" copaMl="60ml / 2oz"/>
                        { licores.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                    </div>
                </div>

                { /* Cocktails */}
                <div id="cocktails" className={ menu.menu_el }>
                    <Image classes={ menu.menu_el_poster } route="menu/bebidas" alt="Cocktails poster"/>
                    <div className={ menu.menu_el_platillos }>
                        <img className={ menu.menu_el_platillos__img } src="/images/menu/bebidas.svg" alt="Cocktails icon"/>
                        <div className={ cx(menu.menu_el_platillos__t1, 'title') }>COCKTAILS</div>
                        <BebitaTitleDef nombre="ITALIAN COCKTAILS"/>
                        { italian.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <BebitaTitleDef nombre="COCKTAIL D´AUTORE"/>
                        { autore.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <BebitaTitleDef nombre="MIXOLOGIA VITTORE"/>
                        { mixologia.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <BebitaTitleDef id="sin-alcohol" nombre="COCTELES VIRGENES"/>
                        { virgenes.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <BebitaTitleDef nombre="DIGESTIVOS"/>
                        { digestivos.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <BebitaTitleDef nombre="SOFT DRINKS"/>
                        { soft.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <BebitaTitleDef id="cervezas" nombre="CERVECERÍA MODELO"/>
                        { cervezas.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <BebitaTitleDef nombre="CERVECERÍA CUAHUTEMOC"/>
                        { cuau.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                        <BebitaTitleDef nombre="CERVESAS ARTESANALES"/>
                        { artesanales.map((el, index) => <BebidaElement key={ index } bebida={ el }/>) }
                    </div>
                </div>
            </div>

            <MenuFloat/>

            <Footer/>
            <Copyright/>
        </>
    )
}