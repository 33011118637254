import home from "./../styles/home.module.css"
import { Navbar } from "../components/Navigation"
import { ResponsiveImage, Image } from "../components/Images"
import cx from "classnames"
import { Footer } from "../components/Footer"
import { Copyright } from "../components/Footer"
import { Link } from "react-router-dom"

export default function Home() {
    return(
        <>
            <Navbar color="black" active="home"/>
            <div className={ home.header }>
                <ResponsiveImage route="home/hero" alt="Centro histórico Mazatlán" classes={ home.header_bg }/>
                <ResponsiveImage route="home/header_comida" alt="Espaguetti" classes={ home.header_title }/>
            </div>

            {/* Bienvenido */}
            <div className={ cx(home.bienvenido, 'section') }>
                <div className={ cx(home.bienvenido_content, 'content') }>
                    <div className={ cx(home.bienvenido__t1, 'title') }>Benvenuto alla Emilia Romagna, benvenuto a</div>
                    <div className={ cx(home.bienvenido__t2, 'title') }>Vittore Terrazo Ristorante</div>
                    <Image route="home/separator" alt="Separator" classes={ home.bienvenido__sep }/>
                </div>
            </div>

            {/* Historia */}
            <div id="historia" className={ cx(home.historia, 'section') }>
                <div className={ home.historia_image }>
                    <Image route="home/historia" alt="Centro histórico Mazatlán" classes={ home.header_bg }/>
                </div>
                <div className={ home.historia_des }>
                    <Image route="home/historia_bg" alt="Background" classes={ home.historia_bg }/>
                    <div className={ home.historia_des__t }>Después de 20 años de haber llegado como un aventurero incansable al puerto de Mazatlán, el lugar donde más he disfrutado, donde mejor me han tratado, donde he madurado y me he vuelto muy conocido.</div>
                    <div className={ home.historia_des__t }>"Aunque me fui por unos meses, ya volví."</div>
                    <div className={ home.historia_des__t }>Regresé para convertir nostra terrazza, trattoria y pizzeria en un ristorante italiano auténtico y único en especie, al que por la esencia del lugar y la energía que transmite, inmediatamente apodé como Terrazzo Ristorante.</div>
                </div>
            </div>

            {/* Platillos */}
            <div className={ cx(home.platillos, 'section') }>
                <Image route="home/platillos_bg" alt="Background" classes={ home.platillos_bg }/>
                <Image route="home/platillos_logo" alt="Logo vittore" classes={ home.platillos_logo }/>
                <Image route="home/platillos" alt="Cubiertos" classes={ home.platillos_cubiertos }/>
                <div className={ cx(home.platillos_content, 'content') }>
                    <div className={ home.platillos_des }>
                        <div className={ cx(home.platillos_des__title, 'title') }>Sirviendo tus<br></br>platillos favoritos<br></br>desde 1997</div>
                        <div className={ home.platillos_des__t1 }>Avanti, Vittore, Avanti! vamos a convertir este lugar en la terraza refinada de Mazatlán, donde se ofrece la comida más auténtica de Emilia Romagna,  la mezclaremos con el gusto refinado de la cultura italiana y vamos a crear un lugar para siempre, ese lugar que llegó hace 20 años para quedarse y nunca irse.</div>
                        <div className={ home.platillos_des__t2 }>Benvenuto alla Emilia Romagna, benvenuto a Vittore Terrazzo Ristorante.</div>
                    </div>

                </div>
            </div>

            {/* Menú */}
            <div className={ cx(home.menu, 'section') }>
                <Image route="home/menu_bg" alt="bg" classes={ home.menu_bg }/>
                <div className={ cx(home.menu_content, 'content') }>
                    <div className={ home.menu_image }>
                        <Image route="home/pizza_menu" alt="Pizza" className={ home.menu_image__img}/>
                    </div>
                    <div className={ (home.menu_des) }>
                        <div className={ cx(home.menu_des__t1, 'title') }>Deléitate con nuestras</div>
                        <div className={ cx(home.menu_des__t2, 'title') }>pastas, pizzas, y la mejor</div>
                        <div className={ cx(home.menu_des__t3, 'title') }>¡cocina Italiana de Mazatlán! </div>
                        <Link to="/menu" className={ cx(home.menu_des__btn, 'title') }>MENÚ</Link>
                    </div>
                </div>
            </div>

            {/* Reservar button */}
            <div className={ cx(home.reservar, 'section') }>
                <div className={ cx(home.reservar_content, 'content') }>
                    <a href="tel:6699862424" className={ cx(home.reservar__button, 'title') }>RESERVAR<span></span></a>
                </div>
            </div>

            <Footer/>
            <Copyright/>
        </>
    )
}