import { Link } from "react-router-dom"

export function Footer() {
    return(
        <div id="contacto" className="footer section">
            <img className="footer_bg" src="/images/footer_bg.png" alt="Logo"/>
            <div className="content">
                <div className="footer_des">
                    <div className="footer_des__t1 title">CONTACTO</div>
                    <a href="tel:6699862424" className="footer_des__link">
                        <img src="/images/tel.png" alt="Tel icon"/>
                        (669) 986 2424
                    </a>
                    <a href="https://goo.gl/maps/9G5ryBK8K4UTTGSc7" target="_blank" className="footer_des__link">
                        <img src="/images/map.png" alt="Map icon"/>
                        Av. Las Gaviotas 100, Dorada, 82110 Mazatlán, Sin.
                    </a>
                    <div className="footer_des_social">
                        <img src="/images/social.png" alt="Social media icons"/>
                        <a href="https://www.facebook.com/VittoreTerrazzoRistorante" target="_blank" className="footer_des_social__link"></a>
                        <a href="https://www.instagram.com/vittoreterrazzo/" target="_blank" className="footer_des_social__link"></a>
                    </div>
                </div>
                <div className="footer_map">
                    <a href="https://goo.gl/maps/9G5ryBK8K4UTTGSc7" target="_blank"></a>
                    <img src="/images/mapa.png" alt="Mapa"/>
                </div>
            </div>
        </div>
    )
}

export function Copyright() {
    return(
        <div className="copy section">
            <div className="content">© Copyright Vittore Mazatlán 2013</div>
        </div>
    )
}