import menu from "./../styles/menu.module.css"
import cx from "classnames"

export function MenuElement({ platillo }) {
    return(
        <div className={ menu.menu_platillo }>
            <div className={ menu.menu_platillo_title }>
                <div className={ cx(menu.menu_platillo__nombre, 'title') }>
                    { platillo.nombre }
                    { platillo.des != null && <span className="title">{ platillo.gramos }</span> }
                </div>
                <div className={ menu.menu_platillo__precio }>{ platillo.precio }</div>
            </div>
            { platillo.des != "" &&
                <div className={ menu.menu_platillo__des }>{ platillo.des }</div>
            }
        </div>
    )
}

export function BebidaTitle({ titulo }) {
    return(
        <div className={ menu.menu_el_platillos_title }>
            <div className={ cx(menu.menu_el_platillos_title__t1, 'title') }>{ titulo }</div>
            <div className={ menu.menu_el_platillos_title__des }>
                Botella<span>750 ml / 25 Oz</span>
            </div>
            <div className={ menu.menu_el_platillos_title__des }>
                Copa<span>160 ml / 5.4 Oz</span>
            </div>
        </div>
    )
}

export function BebidaElement({ bebida }) {
    return(
        <div className={ menu.menu_bebida }>
            <div className={ cx(menu.menu_bebida__nombre, 'title') }>
                { bebida.nombre }
                <span>{ bebida.subtitle }</span>
            </div>
            { bebida.botella != "" && 
                <div className={ menu.menu_bebida__precio }>{ "$" + bebida.botella }</div>
            }
            
            <div className={ menu.menu_bebida__precio }>{ bebida.copa != "" && "$" + bebida.copa }</div>
        </div>
    )
}

export function BebitaTitleDef({ nombre, botella="", botellaMl="", copa="", copaMl="", id}) {
    return(
        <div id={ id } className={ menu.menu_el_platillos_title }>
            <div className={ cx(menu.menu_el_platillos_title__t1, 'title') }>{ nombre }</div>
            
            { botella != "" &&
                <div className={ menu.menu_el_platillos_title__des }>
                    { botella }
                    { botellaMl != "" &&
                        <span>{ botellaMl }</span>
                    }
                </div>
            }

            { copa != "" &&
                <div className={ menu.menu_el_platillos_title__des }>
                    { copa }
                    { copaMl != "" &&
                        <span>{ copaMl }</span>
                    }
                </div>
            }
        </div>
    )
}

export function PlatilloTitleDef({ nombre, botella="", botellaMl="", copa="", copaMl="", id}) {
    return(
        <div id={ id } className={ cx(menu.menu_el_platillos_title, menu.menu_el_platillos_title_platillo) }>
            <div className={ cx(menu.menu_el_platillos_title__t1, 'title') }>{ nombre }</div>
            
            { botella != "" &&
                <div className={ menu.menu_el_platillos_title__des }>
                    { botella }
                    { botellaMl != "" &&
                        <span>{ botellaMl }</span>
                    }
                </div>
            }

            { copa != "" &&
                <div className={ menu.menu_el_platillos_title__des }>
                    { copa }
                    { copaMl != "" &&
                        <span>{ copaMl }</span>
                    }
                </div>
            }
        </div>
    )
}