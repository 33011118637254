'use client'

import { NavHashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
import menu from "./../styles/menu.module.css";
import cx from "classnames";

const open_secondi = () => { 
    document.querySelector('.bebidas').classList.remove('active');
    document.querySelector('.secondi').classList.toggle('active');
}
const open_bebidas = () => { 
    document.querySelector('.secondi').classList.remove('active');
    document.querySelector('.bebidas').classList.toggle('active');
}

const close_secondi = () => { document.querySelector('.secondi').classList.remove('active'); }
const close_bebidas = () => { document.querySelector('.bebidas').classList.remove('active'); }

export function MenuFloat() {
    return(
        <div className={ cx(menu.floating_menu_con, 'floating_menu_flag') }>
            <div className={ menu.floating_sub }>
                <div className={ cx(menu.floating_sub_secondi, 'secondi') }>
                    <NavHashLink onClick={ close_secondi } to="#carne" className={ menu.floating_sub_el }>Carne e pollame</NavHashLink>
                    <NavHashLink onClick={ close_secondi } to="#pesce" className={ menu.floating_sub_el }>Pesce</NavHashLink>
                    <NavHashLink onClick={ close_secondi } to="#paste" className={ menu.floating_sub_el }>Paste</NavHashLink>
                    <NavHashLink onClick={ close_secondi } to="#pizza" className={ menu.floating_sub_el }>Pizza al forno</NavHashLink>
                </div>
                <div className={ cx(menu.floating_sub_bebidas, 'bebidas') }>
                    {/*<NavHashLink onClick={ close_bebidas } to="#vinos" className={ menu.floating_sub_el }>Vinos de mesa</NavHashLink>*/}
                    <NavHashLink onClick={ close_bebidas } to="#destilados" className={ menu.floating_sub_el }>Destilados</NavHashLink>
                    <NavHashLink onClick={ close_bebidas } to="#cocktails" className={ menu.floating_sub_el }>Cocktails</NavHashLink>
                    <NavHashLink onClick={ close_bebidas } to="#sin-alcohol" className={ menu.floating_sub_el }>Sin alcohol</NavHashLink>
                    <NavHashLink onClick={ close_bebidas } to="#cervezas" className={ menu.floating_sub_el }>Cervezas</NavHashLink>
                </div>
            </div>
            <div className={ menu.floating_menu }>
                <NavHashLink className={ menu.floating_menu__el } to="#antipasti">Antipasti</NavHashLink>
                <div onClick={ open_secondi } className={ menu.floating_menu__el }>Secondi</div>
                <NavHashLink className={ menu.floating_menu__el } to="#postres">Postres</NavHashLink>
                <div onClick={ open_bebidas } className={ menu.floating_menu__el }>Bebidas</div>
            </div>
        </div>
    )
}