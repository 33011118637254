/* ANTIPASTI */
export const ensaladas = [
    {
        'nombre':'INSALATA DELLA CASA',
        'gramos':'',
        'precio':'178',
        'des':'Mix de lechugas, tomate cherry, arándano deshidratado, queso de cabra, nuez, almendra, fresa y vinagreta de balsámico.'
    },
    {
        'nombre':'INSALATA VITTORE',
        'gramos':'',
        'precio':'175',
        'des':'Lechuga, pera, nuez, uvas, queso de cabra y aderezo de mostaza.'
    },
    {
        'nombre':'INSALATA CESARE TRADICIONALE',
        'gramos':'',
        'precio':'135',
        'des':'Lechuga romana, aderezo tradicional, crotones y queso parmesano.'
    }
]
export const ensaladasExtras = [
    {
        'nombre':'Camarones',
        'subtitle':'',
        'botella':'',
        'copa':'105'
    },
    {
        'nombre':'Salmón',
        'subtitle':'',
        'botella':'',
        'copa':'125'
    },
    {
        'nombre':'Pollo',
        'subtitle':'',
        'botella':'',
        'copa':'90'
    }
]

export const antipasti = [
    {
        'nombre':'FUNGHI TRIFOLATI',
        'gramos':'',
        'precio':'168',
        'des':'Hongos salteados con mantequilla y guajillo con perejil.'
    },
    {
        'nombre':'MELANZANE RIPIENE DI FORMAGGIO',
        'gramos':'',
        'precio':'175',
        'des':'Berenjenas rellenas de queso de cabra con parmesano, gratinadas con mozzarella.'
    },
    {
        'nombre':'FUNGHI IMPANATI',
        'gramos':'',
        'precio':'190',
        'des':'Champiñones empanizados, con crema de jalapeño y cilantro.'
    },
    {
        'nombre':'CARPACCIO DI MANZO',
        'gramos':'(100gr)',
        'precio':'240',
        'des':'De res, con arúgula y queso parmesano.'
    },
    {
        'nombre':'ASPARAGI ALLA CARBONARA',
        'gramos':'',
        'precio':'205',
        'des':'Espárragos con salsa de panccetta, parmiggiano, yema de huevo y pimienta negra.'
    },
    {
        'nombre':'CARPACCIO DI SALMONE',
        'gramos':'(225gr)',
        'precio':'305',
        'des':'Con salsa de alcaparra.'
    },
    {
        'nombre':'CALAMARI FRITTI',
        'gramos':'',
        'precio':'205',
        'des':'Empanizados con salsa tártara ó fritos con salsa marinara.'
    },
    {
        'nombre':'MINISTRONE DELLA NONNA',
        'gramos':'',
        'precio':'135',
        'des':'Con alubias, pasta cabello de ángel y brócoli.'
    }
]

/* CARNE E POLLAME */
export const carne = [
    {
        'nombre':'PETTO DI POLLO ALLA PARMIGGIANO',
        'gramos':'(220gr)',
        'precio':'260',
        'des':'Pechuga empanizada con parmesano sobre pasta a la mantequilla.'
    },
    {
        'nombre':'PETTO DI POLLO PICATTA',
        'gramos':'(220gr)',
        'precio':'260',
        'des':'Pechuga de pollo a la parrilla, salsa picatta, con vegetales y pasta.'
    },
    {
        'nombre':'PETTO DI POLLO ALLA CACCIATORE',
        'gramos':'(220gr)',
        'precio':'260',
        'des':'Con espejo de casse de tomate, con pasta y calabaza a la parrilla.'
    },
    {
        'nombre':'COSTATA DI AGNELO ALLA GRIGLIA',
        'gramos':'(250gr)',
        'precio':'570',
        'des':'Con tomates asados y papas cambray.'
    },
    {
        'nombre':'BAVVETA DI MANZO ALLA GRIGLIA',
        'gramos':'(300gr)',
        'precio':'390',
        'des':'Con papas cambray y pasta.'
    },
    {
        'nombre':'FILETTO DI MANZO',
        'gramos':'(225g)',
        'precio':'390',
        'des':'Filete de res en salsa de pimienta, papas cambray y pasta.'
    },
    {
        'nombre':'FILETTO DI MANZO VALDOSTANA',
        'gramos':'(225gr)',
        'precio':'420',
        'des':'Filete de res a la parrilla, sobre champiñones gratinados con puré de papa y pasta.'
    },
    {
        'nombre':'SALTINBOCCA ALLA ROMANA',
        'gramos':'(225gr)',
        'precio':'390',
        'des':'Escalopas de filete de res, con proscuitto, gravy al limón, timbal de espárragos y puré de papa.'
    },
    {
        'nombre':'RIB EYE',
        'gramos':'(450gr)',
        'precio':'632',
        'des':'Pintado con demi-glace de la casa, papas cambray y pasta.'
    }
]

/* PESCE */
export const pesce = [
    {
        'nombre':'POLPO ALLA GRIGLIA',
        'gramos':'(200gr)',
        'precio':'360',
        'des':'Con aderezo de atún, julianas de vegetales y papas cambray.'
    },
    {
        'nombre':'SALMONE CON SALSA DI GAMBERI',
        'gramos':'(225gr)',
        'precio':'340',
        'des':'Con arroz y vegetales.'
    },
    {
        'nombre':'PESCE ALLA FLORENTINA',
        'gramos':'(200gr)',
        'precio':'290',
        'des':'Con vegetales y pasta.'
    },
    {
        'nombre':'PESCE SPADA ALLA GRIGLIA',
        'gramos':'(225gr)',
        'precio':'295',
        'des':'Sobre espinacas, con vegetales y arroz.'
    },
    {
        'nombre':'ENVOLTINI DI PROSCIUTTO CON GAMBERI',
        'gramos':'(200gr)',
        'precio':'380',
        'des':'Camarones envueltos con prosciutto, rellenos de queso de cabra y morrón, servidos sobre pasta.'
    }
]

/* PASTE */
export const paste = [
    {
        'nombre':'SPAGHETTI ALLA BOLOGNESE',
        'gramos':'(250gr)',
        'precio':'255',
        'des':'Con salsa de carne tradicional y parmesano.'
    },
    {
        'nombre':'LINGUINI ALLA PESCATORA',
        'gramos':'(250gr)',
        'precio':'285',
        'des':'Receta napolitana de mariscos y pomodoro.'
    },
    {
        'nombre':'FETTUCCINE ALFREDO CON GAMBERI',
        'gramos':'(250gr)',
        'precio':'290',
        'des':'Tallarines con camarones en salsa blanca, ajo y cebolla.'
    },
    {
        'nombre':'RAVIOLIS RIPIENI DI SALMONE',
        'gramos':'(250gr)',
        'precio':'290',
        'des':'Ravioles rellenos de salmón, servidos con salsa Alfredo, pomodoro o manteqilla.'
    },
    {
        'nombre':'SPAGHETTI ALLA PUTANESCA',
        'gramos':'(250gr)',
        'precio':'185',
        'des':'Pasta larga con salsa de tomate, ajo, anchoas, alcaparras, aceitunas y perejil.'
    },
    {
        'nombre':'PENNE ALLA RABIATA',
        'gramos':'(250gr)',
        'precio':'232',
        'des':'Tubos cortos de pasta con salsa de tomate, chile y ajo guisados con aceite de olivo.'
    },
    {
        'nombre':'LASAGNA ALLA BOLOGNESE',
        'gramos':'(300gr)',
        'precio':'255',
        'des':'Con salsa de carne, tomate y parmiggiano reggiano.'
    },
    {
        'nombre':'CAPELLINI VERDURE E MARINATE',
        'gramos':'(250gr)',
        'precio':'232',
        'des':'Pasta cabello de ángel salteada con verduras de temporada con salsa de vino blanco y perejil, con queso de cabra.'
    },
    {
        'nombre':'TAGLIATELLE CON POLLO',
        'gramos':'(250gr)',
        'precio':'238',
        'des':'Pasta larga con pechuga de pollo, en salsa de tomate, con brócoli y champiñones.'
    },
    {
        'nombre':'FARFALLE MARE E MONTI',
        'gramos':'(250gr)',
        'precio':'288',
        'des':'Moñitos de pasta salteados con camarones, prosciutto con salsa cremosa.'
    },
    {
        'nombre':'CANELLONI DI GAMBERI E RICOTTA',
        'gramos':'(250gr)',
        'precio':'238',
        'des':'Canelones rellenos de camarón y queso ricota, gratinados servidos con salsa pomodoro o Alfredo.'
    },
    {
        'nombre':'SPAGHETTI ALLA SICILIANA',
        'gramos':'(250gr)',
        'precio':'232',
        'des':'Con berenjenas, tomate deshidratado y champiñones.'
    },
]

/* PIZZA AL FORNO */
export const pizza = [
    {
        'nombre':'BBQ CON POLLO',
        'gramos':'',
        'precio':'355',
        'des':'Con piña asada.'
    },
    {
        'nombre':'SICILIANA',
        'gramos':'',
        'precio':'255',
        'des':'Chorizo Siciliano y pimientos rostizados.'
    },
    {
        'nombre':'MARGHERITA',
        'gramos':'',
        'precio':'220',
        'des':'La auténtica margarita, tomate, mozzarella, pimiento amarillo, aceite de olivo y albahaca.'
    },
    {
        'nombre':'CAPRICCIOSA DELLA NONNA',
        'gramos':'',
        'precio':'295',
        'des':'Mozzarella, salsa de tomate, salami, jamón de pierna, champiñones y pimientos.'
    },
    {
        'nombre':'QUATRO FORMAGGI (Cuatro quesos)',
        'gramos':'',
        'precio':'325',
        'des':'Mezcla de mozzarella, parmesano y queso de cabra.'
    },
    {
        'nombre':'AI FRUTTI DI MARE',
        'gramos':'',
        'precio':'350',
        'des':'Tradicional con salsa pomodoro, orégano, camarón, pulpo y calamar.'
    },
    {
        'nombre':'AMERICANA',
        'gramos':'',
        'precio':'255',
        'des':'Salsa de tomate, mozzarella y “pepperoni”'
    },
    {
        'nombre':'SALSICCIA',
        'gramos':'',
        'precio':'237',
        'des':'Salsa de tomate, salchicha italiana, aceituna negra y tomate deshidratado.'
    },
    {
        'nombre':'QUATRO STAGIONI',
        'gramos':'',
        'precio':'295',
        'des':'Jamón, champiñón, aceituna y alcachofa.'
    },
    {
        'nombre':'NAPOLI',
        'gramos':'',
        'precio':'280',
        'des':'Salami, pepperoni, pimientos y anchoas.'
    },
]
export const pizzasExtras = [
    {
        'nombre':'Camarones al grill',
        'subtitle':'',
        'botella':'',
        'copa':'105'
    },
    {
        'nombre':'Pepperoni',
        'subtitle':'',
        'botella':'',
        'copa':'55'
    },
    {
        'nombre':'Proscuitto',
        'subtitle':'',
        'botella':'',
        'copa':'65'
    },
    {
        'nombre':'Servicio de pan y queso',
        'subtitle':'',
        'botella':'',
        'copa':'35'
    },
    {
        'nombre':'Queso Extra',
        'subtitle':'',
        'botella':'',
        'copa':'65'
    },
    {
        'nombre':'Champiñón',
        'subtitle':'',
        'botella':'',
        'copa':'55'
    },
    {
        'nombre':'Aceituna Negra',
        'subtitle':'',
        'botella':'',
        'copa':'55'
    }
]

/* POSTRES */
export const postres = [
    {
        'nombre':'TIRAMISU',
        'gramos':'',
        'precio':'138',
        'des':'Tradicional pastel Italiano a base de café, queso mascarpone y chocolate.'
    },
    {
        'nombre':'PASTEL DE CHOCOLATE',
        'gramos':'',
        'precio':'125',
        'des':''
    },
    {
        'nombre':'FLAN DE COCO',
        'gramos':'',
        'precio':'115',
        'des':''
    },
    {
        'nombre':'HELADO DE VAINILLA',
        'gramos':'',
        'precio':'115',
        'des':''
    },
    {
        'nombre':'PASTEL DE QUESO CON FRESA',
        'gramos':'',
        'precio':'138',
        'des':''
    },
]

/* VINOS DE MESA */
export const vinos = [
    {
        'nombre':'DOM PERIGNON',
        'subtitle':'2006, Francia',
        'botella':'12,950',
        'copa':''
    },
    {
        'nombre':'MOET & CHANDON',
        'subtitle':'Nectar Imperilal, NV Francia',
        'botella':'3,110',
        'copa':''
    },
    {
        'nombre':'MOET & CHANDON',
        'subtitle':'Imperial NV Francia',
        'botella':'3,210',
        'copa':''
    },
]

/* VINOS ESPUMOSOS */
export const vinos_espumosos = [
    {
        'nombre':'DIAMANTI',
        'subtitle':'Lambrusco DELL ́Emilia, Italia',
        'botella':'680',
        'copa':''
    },
    {
        'nombre':'FOGOSO GOLD',
        'subtitle':'Semi Dulce',
        'botella':'1,040',
        'copa':''
    },
]

/* VINOS BLANCOS */
export const vinos_blancos = [
    {
        'nombre':'LIBERTAS',
        'subtitle':'Sauvignon Blanc, Valle Central, chile',
        'botella':'490',
        'copa':'150'
    },
    {
        'nombre':'CASA MADERO 2V',
        'subtitle':'Chardonay/Chenin Blanc, Mex.',
        'botella':'1,020',
        'copa':''
    },
    {
        'nombre':'PRIMISOLI',
        'subtitle':'Pinot Grigio, Lazio, Italia',
        'botella':'620',
        'copa':'165'
    },
    {
        'nombre':'LIBERTAS Chardonay',
        'subtitle':'Valle Central, Chile',
        'botella':'490',
        'copa':'150'
    },
]

/* VINOS ROSADOS */
export const vinos_rosados = [
    {
        'nombre':'BERINGER',
        'subtitle':'White Zinfandel, California EEUU',
        'botella':'845',
        'copa':''
    },
    {
        'nombre':'LIBERTAS ROSÉ',
        'subtitle':'Valle Central, Chile',
        'botella':'490',
        'copa':'150'
    },
    {
        'nombre':'CASA MADERO V',
        'subtitle':'Parras de la Fuente Coahuila, México',
        'botella':'1,040',
        'copa':''
    },
    {
        'nombre':'SATIS DEI',
        'subtitle':'Tempranillo, D. O. Castilla, España',
        'botella':'1,020',
        'copa':''
    },
]

/* VINOS TINTOS */
export const vinos_tintos = [
    {
        'nombre':'LIBERTAS',
        'subtitle':'Merlot, Valle Central, Chile',
        'botella':'490',
        'copa':'150'
    },
    {
        'nombre':'LIBERTAS',
        'subtitle':'Cabernet, Tierra de Castilla España',
        'botella':'620',
        'copa':'165'
    },
    {
        'nombre':'INDIGO EYES',
        'subtitle':'Pinot Noir, California EEUU',
        'botella':'870',
        'copa':''
    },
    {
        'nombre':'EL MOLINO',
        'subtitle':'Malbec, Mendoza, Argentina',
        'botella':'705',
        'copa':''
    },
    {
        'nombre':'LA CETTO NEBBIOLO',
        'subtitle':'Reserva Priv. México',
        'botella':'810',
        'copa':''
    },
    {
        'nombre':'CASA MADERO',
        'subtitle':'Shiraz Parras Coahuila, México',
        'botella':'1,395',
        'copa':''
    },
    {
        'nombre':'CASA MADERO 3V',
        'subtitle':'Cab. Sauv., Tempranillo, Merlot',
        'botella':'1,350',
        'copa':''
    },
    {
        'nombre':'INEDITO',
        'subtitle':'Mb, Prim., Granache, Caladoc Qro. Mex',
        'botella':'1,295',
        'copa':''
    },
]

/* RON */
export const ron = [
    {
        'nombre':'MALIBÚ',
        'subtitle':'750 ML',
        'botella':'1,100',
        'copa':'110'
    },
    {
        'nombre':'APPLETON STATE SIGNATURE',
        'subtitle':'750 ML',
        'botella':'1,250',
        'copa':'115'
    },
    {
        'nombre':'APPLETON STATE 12 AÑOS',
        'subtitle':'750 ML',
        'botella':'2,440',
        'copa':'225'
    },
    {
        'nombre':'BACARDI 8',
        'subtitle':'700 ML ',
        'botella':'2,100',
        'copa':'215'
    },
    {
        'nombre':'BACARDI BLANCO',
        'subtitle':'750 ML',
        'botella':'1,140',
        'copa':'95'
    },
    {
        'nombre':'BOTRAN AÑEJO 8',
        'subtitle':'750 ML',
        'botella':'2,265',
        'copa':'210'
    },
    {
        'nombre':'CAPITAN MORGAN',
        'subtitle':'750 ML',
        'botella':'950',
        'copa':'95'
    },
    {
        'nombre':'FLOR DE CAÑA 4 DRY',
        'subtitle':'750 ML',
        'botella':'980',
        'copa':'98'
    },
    {
        'nombre':'HAVANA CLUB 7 AÑEJO',
        'subtitle':'700 ML',
        'botella':'1,800',
        'copa':'170'
    },
    {
        'nombre':'MATUZALEM CLASICO',
        'subtitle':'750 ML',
        'botella':'1,280',
        'copa':'110'
    },
    {
        'nombre':'ZACAPA 23',
        'subtitle':'750 ML',
        'botella':'3,600',
        'copa':'310'
    },
]

/* TEQUILA */
export const tequila = [
    {
        'nombre':'7 LEGUAS BLANCO',
        'subtitle':'750 ML',
        'botella':'2,440',
        'copa':'210'
    },
    {
        'nombre':'7 LEGUAS REPOSADO',
        'subtitle':'750 ML',
        'botella':'2,730',
        'copa':'250'
    },
    {
        'nombre':'CENTENARIO PLATA',
        'subtitle':'700 ML',
        'botella':'1,860',
        'copa':'175'
    },
    {
        'nombre':'DOBEL DIAMANTE',
        'subtitle':'750 ML',
        'botella':'2,900',
        'copa':'255'
    },
    {
        'nombre':'DON JULIO 70',
        'subtitle':'750 ML',
        'botella':'3,715',
        'copa':'320'
    },
    {
        'nombre':'DON JULIO BLANCO',
        'subtitle':'750 ML',
        'botella':'2,380',
        'copa':'205'
    },
    {
        'nombre':'DON JULIO REPOSADO',
        'subtitle':'750 ML',
        'botella':'2,845',
        'copa':'245'
    },
    {
        'nombre':'HERRADURA ANTIGUO PLATA',
        'subtitle':'700 ML',
        'botella':'1,390',
        'copa':'130'
    },
    {
        'nombre':'HERRADURA BLANCO',
        'subtitle':'750 ML',
        'botella':'3,020',
        'copa':'220'
    },
    {
        'nombre':'HERRADURA REPOSADO',
        'subtitle':'700 ML',
        'botella':'2,900',
        'copa':'260'
    },
    {
        'nombre':'HERRADURA ULTRA',
        'subtitle':'700 ML',
        'botella':'2,620',
        'copa':'255'
    },
]

/* VODKA */
export const vodka = [
    {
        'nombre':'BELVEDERE',
        'subtitle':'750 ML',
        'botella':'2,960',
        'copa':'255'
    },
    {
        'nombre':'CIROC',
        'subtitle':'750 ML',
        'botella':'2,495',
        'copa':'230'
    },
    {
        'nombre':'GREY GOOSE',
        'subtitle':'750 ML',
        'botella':'2,845',
        'copa':'255'
    },
    {
        'nombre':'KETEL ONE',
        'subtitle':'750 ML',
        'botella':'1,915',
        'copa':'175'
    },
    {
        'nombre':'STLOSHNAYA',
        'subtitle':'750 ML',
        'botella':'1,280',
        'copa':'120'
    },
    {
        'nombre':'ABSOLUT',
        'subtitle':'750 ML',
        'botella':'1,220',
        'copa':'110'
    },
    {
        'nombre':'SMIRNOF',
        'subtitle':'750 ML',
        'botella':'990',
        'copa':'100'
    },
]

/* BOURBON */
export const bourbon = [
    {
        'nombre':'BULIET BOURBON',
        'subtitle':'750 ML',
        'botella':'1,915',
        'copa':'175'
    },
    {
        'nombre':'MAKERS MARK',
        'subtitle':'750 ML',
        'botella':'1,980',
        'copa':'185'
    },
    {
        'nombre':'WILD TURKEY RYE',
        'subtitle':'750 ML',
        'botella':'1,750',
        'copa':'165'
    },
]

/* IRISH WHISKEY */
export const irish_whiskey = [
    {
        'nombre':'JAMESON',
        'subtitle':'750 ML',
        'botella':'1,750',
        'copa':'165'
    },
]

/* CANADIAN WHISKEY */
export const canadian_whiskey = [
    {
        'nombre':'CANADIAN CLUB',
        'subtitle':'750 ML',
        'botella':'1,280',
        'copa':'120'
    },
    {
        'nombre':'CROWN ROYAL',
        'subtitle':'750 ML',
        'botella':'2,100',
        'copa':'190'
    },
]

/* SCOTCH WHISKY */
export const scotch = [
    {
        'nombre':'JW BLACK LABEL',
        'subtitle':'750 ML',
        'botella':'3,365',
        'copa':'300'
    },
    {
        'nombre':'JW DOUBLE BLACK',
        'subtitle':'750 ML',
        'botella':'4,295',
        'copa':'380'
    },
    {
        'nombre':'JW GOLD LABEL',
        'subtitle':'750 ML',
        'botella':'4,700',
        'copa':'440'
    },
    {
        'nombre':'JW GREEN LABEL',
        'subtitle':'750 ML',
        'botella':'4,295',
        'copa':'380'
    },
    {
        'nombre':'JW BLUE LABEL',
        'subtitle':'750 ML',
        'botella':'13,950',
        'copa':'1,220'
    },
    {
        'nombre':'BUCHANANS 12',
        'subtitle':'750 ML',
        'botella':'2,550',
        'copa':'245'
    },
    {
        'nombre':'BUCHANANS 18',
        'subtitle':'750 ML',
        'botella':'4,700',
        'copa':'440'
    },
    {
        'nombre':'CHIVAS REAGAL 18',
        'subtitle':'750 ML',
        'botella':'4,930',
        'copa':'465'
    },
    {
        'nombre':'CHIVAS 12',
        'subtitle':'750 ML',
        'botella':'2,380',
        'copa':'230'
    },
    {
        'nombre':'JACK DANIELS',
        'subtitle':'700 ML',
        'botella':'1,800',
        'copa':'185'
    },
    {
        'nombre':'JACK DANIELS HONEY',
        'subtitle':'700 ML',
        'botella':'1,625',
        'copa':'165'
    },
    {
        'nombre':'OLD PARR 12',
        'subtitle':'750 ML',
        'botella':'2,440',
        'copa':'235'
    },
]

/* SINGLE MALT */
export const single_malt = [
    {
        'nombre':'GLENFIDDICH 12',
        'subtitle':'750 ML',
        'botella':'3,770',
        'copa':'340'
    },
    {
        'nombre':'MACALAN 12',
        'subtitle':'700 ML',
        'botella':'4,875',
        'copa':'440'
    },
    {
        'nombre':'GLENLIVET 12',
        'subtitle':'750 ML',
        'botella':'3,250',
        'copa':'300'
    },
]

/* BRANDY */
export const brandy = [
    {
        'nombre':'CARLOS 1',
        'subtitle':'700 ML',
        'botella':'2,960',
        'copa':'280'
    },
    {
        'nombre':'TORRES 10',
        'subtitle':'700 ML',
        'botella':'1,450',
        'copa':'150'
    },
]

/* COGNAC */
export const cognac = [
    {
        'nombre':'HENNESSY VS',
        'subtitle':'700 ML',
        'botella':'3,365',
        'copa':'325'
    },
    {
        'nombre':'HENNESSY VSOP',
        'subtitle':'700 ML',
        'botella':'4,870',
        'copa':'475'
    },
    {
        'nombre':'MARTEL VSOP',
        'subtitle':'700 ML',
        'botella':'2,780',
        'copa':'270'
    },
]

/* GINEBRA */
export const ginebra = [
    {
        'nombre':'TANQUERAY TEN',
        'subtitle':'700 ML',
        'botella':'2,440',
        'copa':'245'
    },
    {
        'nombre':'TANQUERAY DRY',
        'subtitle':'750 ML',
        'botella':'1,510',
        'copa':'150'
    },
    {
        'nombre':'BOMBAY SHAPPHIRE',
        'subtitle':'750 ML',
        'botella':'1,850',
        'copa':'180'
    },
    {
        'nombre':'BULLDOG',
        'subtitle':'750 ML',
        'botella':'2,200',
        'copa':'210'
    },
    {
        'nombre':'HENDRICKS',
        'subtitle':'750 ML',
        'botella':'3,250',
        'copa':'290'
    },
    {
        'nombre':'MOM',
        'subtitle':'700 ML',
        'botella':'2,150',
        'copa':'220'
    },
    {
        'nombre':'THE LONDON No. 1',
        'subtitle':'700 ML',
        'botella':'2,900',
        'copa':'280'
    },
    {
        'nombre':'BEEFEATER',
        'subtitle':'750 ML',
        'botella':'1,600',
        'copa':'150'
    },
]

/* MEZCAL */
export const mezcal = [
    {
        'nombre':'DULCE INFIERNO CENIZO JOVEN',
        'subtitle':'750 ML',
        'botella':'2,730',
        'copa':'280'
    },
    {
        'nombre':'AMORES JOVEN ESPADIN',
        'subtitle':'750 ML',
        'botella':'2,380',
        'copa':'240'
    },
    {
        'nombre':'AMORES REPOSADO ESPADIN',
        'subtitle':'750 ML',
        'botella':'2,200',
        'copa':'220'
    },
    {
        'nombre':'UNION EL VIEJO ESPADIN. TOBALÁ',
        'subtitle':'700 ML',
        'botella':'2,780',
        'copa':'290'
    },
    {
        'nombre':'UNION UNO ESPADIN, CIRIAL',
        'subtitle':'700 ML',
        'botella':'1,950',
        'copa':'210'
    },
]

/* LICORES */
export const licores = [
    {
        'nombre':'AMARETTO DISARONO',
        'subtitle':'',
        'botella':'',
        'copa':'135'
    },
    {
        'nombre':'ANCHO REYES',
        'subtitle':'',
        'botella':'',
        'copa':'120'
    },
    {
        'nombre':'ANCHO REYES VERDE',
        'subtitle':'',
        'botella':'',
        'copa':'105'
    },
    {
        'nombre':'APEROL',
        'subtitle':'',
        'botella':'',
        'copa':'110'
    },
    {
        'nombre':'BAILYES',
        'subtitle':'',
        'botella':'',
        'copa':'120'
    },
    {
        'nombre':'CAMPARI',
        'subtitle':'',
        'botella':'',
        'copa':'120'
    },
    {
        'nombre':'CHARTEUSE AMARILLO',
        'subtitle':'',
        'botella':'',
        'copa':'205'
    },
    {
        'nombre':'CHARTREUSE VERDE',
        'subtitle':'',
        'botella':'',
        'copa':'240'
    },
    {
        'nombre':'FERNET BRANCA',
        'subtitle':'',
        'botella':'',
        'copa':'150'
    },
    {
        'nombre':'FRANGELICO',
        'subtitle':'',
        'botella':'',
        'copa':'180'
    },
    {
        'nombre':'GRAND MENIER',
        'subtitle':'',
        'botella':'',
        'copa':'210'
    },
    {
        'nombre':'KAHLUA',
        'subtitle':'',
        'botella':'',
        'copa':'90'
    },
    {
        'nombre':'LICOR 43',
        'subtitle':'',
        'botella':'',
        'copa':'190'
    },
    {
        'nombre':'LICOR 43 OROCHATA',
        'subtitle':'',
        'botella':'',
        'copa':'160'
    },
    {
        'nombre':'RUM CHATA',
        'subtitle':'',
        'botella':'',
        'copa':'140'
    },
    {
        'nombre':'SAMBUCA NEGRO',
        'subtitle':'',
        'botella':'',
        'copa':'124'
    },
    {
        'nombre':'SAMBUCA BLANCO',
        'subtitle':'',
        'botella':'',
        'copa':'118'
    },
    {
        'nombre':'STREGA',
        'subtitle':'',
        'botella':'',
        'copa':'180'
    },
    {
        'nombre':'PAMA (LICOR DE GRANADA)',
        'subtitle':'',
        'botella':'',
        'copa':'170'
    },
]

/* ITALIAN COCKTAILS */
export const italian = [
    {
        'nombre':'ROSSINI',
        'subtitle':'',
        'botella':'',
        'copa':'185'
    },
    {
        'nombre':'APEROL SPRITZ',
        'subtitle':'',
        'botella':'',
        'copa':'185'
    },
    {
        'nombre':'ITALIA SOLEGGIATA',
        'subtitle':'',
        'botella':'',
        'copa':'165'
    },
    {
        'nombre':'SGROPINO',
        'subtitle':'',
        'botella':'',
        'copa':'185'
    },
    {
        'nombre':'KIR SPRITZ',
        'subtitle':'',
        'botella':'',
        'copa':'165'
    },
    {
        'nombre':'FRANGIPANE COCKTAIL',
        'subtitle':'',
        'botella':'',
        'copa':'175'
    },
    {
        'nombre':'RUMORE',
        'subtitle':'',
        'botella':'',
        'copa':'165'
    },
    {
        'nombre':'NEGRONI',
        'subtitle':'',
        'botella':'',
        'copa':'185'
    },
]

/* AUTORE */
export const autore = [
    {
        'nombre':'BLACK BONES',
        'subtitle':'',
        'botella':'',
        'copa':'165'
    },
    {
        'nombre':'MOSCOW MULE',
        'subtitle':'',
        'botella':'',
        'copa':'165'
    },
    {
        'nombre':'ELIXIR',
        'subtitle':'',
        'botella':'',
        'copa':'175'
    },
    {
        'nombre':'GINEPRO',
        'subtitle':'',
        'botella':'',
        'copa':'165'
    },
]

/* MIXOLOGÍA */
export const mixologia = [
    {
        'nombre':'BERRY BUENO',
        'subtitle':'',
        'botella':'',
        'copa':'175'
    },
    {
        'nombre':'CALIOPE',
        'subtitle':'',
        'botella':'',
        'copa':'175'
    },
    {
        'nombre':'PSIDIUM LUXE',
        'subtitle':'',
        'botella':'',
        'copa':'180'
    },
    {
        'nombre':'SUNSET',
        'subtitle':'',
        'botella':'',
        'copa':'175'
    },
    {
        'nombre':'ANDRÓMEDA',
        'subtitle':'',
        'botella':'',
        'copa':'175'
    },
    {
        'nombre':'MALAQUITA',
        'subtitle':'',
        'botella':'',
        'copa':'175'
    },
    {
        'nombre':'PALMAR',
        'subtitle':'',
        'botella':'',
        'copa':'180'
    },
    {
        'nombre':'ECLIPSE',
        'subtitle':'',
        'botella':'',
        'copa':'185'
    },
    {
        'nombre':'TAMARINDO STYLE',
        'subtitle':'',
        'botella':'',
        'copa':'175'
    },
    {
        'nombre':'IRIS',
        'subtitle':'',
        'botella':'',
        'copa':'195'
    },
    {
        'nombre':'MONARCA',
        'subtitle':'',
        'botella':'',
        'copa':'175'
    },
    {
        'nombre':'EMPERADOR',
        'subtitle':'',
        'botella':'',
        'copa':'175'
    },
    {
        'nombre':'SUMMER',
        'subtitle':'',
        'botella':'',
        'copa':'195'
    },
    {
        'nombre':'APOLO',
        'subtitle':'',
        'botella':'',
        'copa':'175'
    },
    {
        'nombre':'MEXICANITO',
        'subtitle':'',
        'botella':'',
        'copa':'175'
    },
    {
        'nombre':'TROPICAL',
        'subtitle':'',
        'botella':'',
        'copa':'175'
    },
    {
        'nombre':'NEW CLASIC',
        'subtitle':'',
        'botella':'',
        'copa':'175'
    },
    {
        'nombre':'BINGO',
        'subtitle':'',
        'botella':'',
        'copa':'185'
    },
]

/* VIRGENES */
export const virgenes = [
    {
        'nombre':'MARIA',
        'subtitle':'',
        'botella':'',
        'copa':'97'
    },
    {
        'nombre':'GIALO',
        'subtitle':'',
        'botella':'',
        'copa':'97'
    },
    {
        'nombre':'JAMAICA STYLE',
        'subtitle':'',
        'botella':'',
        'copa':'97'
    },
    {
        'nombre':'POMELO SPICY',
        'subtitle':'',
        'botella':'',
        'copa':'97'
    },
    {
        'nombre':'SANTO REMEDIO',
        'subtitle':'',
        'botella':'',
        'copa':'97'
    },
]

/* DIGESTIVOS */
export const digestivos = [
    {
        'nombre':'CARAJILLO',
        'subtitle':'',
        'botella':'',
        'copa':'185'
    },
    {
        'nombre':'CARAJILLO BAILEYS',
        'subtitle':'',
        'botella':'',
        'copa':'185'
    },
    {
        'nombre':'ZACARILLO',
        'subtitle':'',
        'botella':'',
        'copa':'185'
    },
    {
        'nombre':'CAFÉ IRLANDES',
        'subtitle':'',
        'botella':'',
        'copa':'185'
    },
]

/* SOFT DRINKS */
export const soft = [
    {
        'nombre':'REFRESCOS COCA COLA',
        'subtitle':'355 ML',
        'botella':'',
        'copa':'50'
    },
    {
        'nombre':'NARANJADA',
        'subtitle':'270 ML',
        'botella':'',
        'copa':'52'
    },
    {
        'nombre':'LIMONADA',
        'subtitle':'270 ML',
        'botella':'',
        'copa':'52'
    },
    {
        'nombre':'PERRIER',
        'subtitle':'330 ML',
        'botella':'',
        'copa':'80'
    },
    {
        'nombre':'A. MINERAL T. CHICO',
        'subtitle':'340 ML',
        'botella':'',
        'copa':'50'
    },
    {
        'nombre':'AGUA ST. PELLEGRINO',
        'subtitle':'250 ML',
        'botella':'',
        'copa':'50'
    },
    {
        'nombre':'GINGER ALE',
        'subtitle':'355 ML',
        'botella':'',
        'copa':'55'
    },
    {
        'nombre':'AGUA CIEL',
        'subtitle':'',
        'botella':'',
        'copa':'40'
    },
]

/* CERVEZAS MODELO */
export const cervezas = [
    {
        'nombre':'CORONA',
        'subtitle':'355 ML',
        'botella':'',
        'copa':'55'
    },
    {
        'nombre':'PACIFICO',
        'subtitle':'355 ML',
        'botella':'',
        'copa':'55'
    },
    {
        'nombre':'NEGRA MODELO',
        'subtitle':'355 ML',
        'botella':'',
        'copa':'76'
    },
    {
        'nombre':'MODELO ESPECIAL',
        'subtitle':'355 ML',
        'botella':'',
        'copa':'76'
    },
    {
        'nombre':'BUD LIGHT',
        'subtitle':'355 ML',
        'botella':'',
        'copa':'80'
    },
    {
        'nombre':'MICHELOB ULTRA',
        'subtitle':'355 ML',
        'botella':'',
        'copa':'80'
    },
    {
        'nombre':'STELLA ARTROIS',
        'subtitle':'355 ML',
        'botella':'',
        'copa':'90'
    },
    {
        'nombre':'PACIFICO SUAVE',
        'subtitle':'355 ML',
        'botella':'',
        'copa':'58'
    },
]

/* CERVECERÍA CUAHUTEMOC */
export const cuau = [
    {
        'nombre':'INDIO',
        'subtitle':'325 ML',
        'botella':'',
        'copa':'55'
    },
    {
        'nombre':'TECATE LIGHT',
        'subtitle':'325 ML',
        'botella':'',
        'copa':'55'
    },
    {
        'nombre':'XX LAGER',
        'subtitle':'325 ML',
        'botella':'',
        'copa':'55'
    },
    {
        'nombre':'BOHEMIA CLARA',
        'subtitle':'355 ML',
        'botella':'',
        'copa':'75'
    },
    {
        'nombre':'BOHEMIA OSCURA',
        'subtitle':'355 ML',
        'botella':'',
        'copa':'75'
    },
    {
        'nombre':'MILLER HIGH LIFE',
        'subtitle':'355 ML',
        'botella':'',
        'copa':'90'
    },
    {
        'nombre':'HEINEKEN',
        'subtitle':'355 ML',
        'botella':'',
        'copa':'85'
    },
]

/* CERVEZAS ARTESANALES */
export const artesanales = [
    {
        'nombre':'TIKUS PORTER',
        'subtitle':'355 ML',
        'botella':'',
        'copa':'105'
    },
    {
        'nombre':'COLIMITA LAGER',
        'subtitle':'355 ML',
        'botella':'',
        'copa':'105'
    },
    {
        'nombre':'PARAMO PALE ALE',
        'subtitle':'355 ML',
        'botella':'',
        'copa':'105'
    },
]




/* COMIDA EXAMPLE */
export const example = [
    {
        'nombre':'',
        'gramos':'',
        'precio':'',
        'des':''
    },
]

/* BEBIDA EXAMPLE */
export const bebida_example = [
    {
        'nombre':'',
        'subtitle':'',
        'botella':'',
        'copa':''
    },
]